import { SelectOption } from '@/common/components/form-controls-deprecated';

type PermissionResponse = {
  realm: string;
  scope: string;
  subject: string;
};

type RoleResponse = {
  displayName: string;
  id: string;
  permissions: PermissionResponse[];
};

export type RoleListResponse = RoleResponse[];

export interface RoleDictionaryElementVM extends SelectOption {}
export class RoleDictionaryElementVM {
  constructor({ id, displayName }: Pick<RoleResponse, 'id' | 'displayName'>) {
    this.label = displayName;
    this.value = id;
  }
}

export interface RoleDictionaryVM {
  list: RoleDictionaryElementVM[];
}

export class RoleDictionaryVM {
  constructor(roleList: RoleListResponse) {
    this.list = (roleList || []).map(element => new RoleDictionaryElementVM(element));
  }
}
