import * as Yup from 'yup';

import { type FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

type ValueOf<T> = T[keyof T];
type FormValidatorSpec<TFieldValues extends FieldValues = FieldValues> = Record<keyof TFieldValues, ValueOf<Yup.ObjectShape>>;

export const createFormValidator =
  <TFieldValues extends FieldValues = FieldValues>() =>
  <TSpec extends FormValidatorSpec<TFieldValues> = FormValidatorSpec<TFieldValues>>(
    spec: TSpec,
    deps?: [string, string][],
    schemaOptions?: Parameters<ReturnType<typeof Yup.object<Yup.AnyObject, TSpec>>['validate']>[1],
    resolverOptions?: Parameters<typeof yupResolver>['2'],
  ) =>
    yupResolver(Yup.object().shape(spec, deps),
      schemaOptions, resolverOptions);
