import { CustomFile, SelectOption, AsyncSelectOption } from '@/common/components/form-controls-deprecated';
import { PageableListResponse, PageableListVM, ApiPagination } from '@/common/utils/pageable';
import { Enum } from '@/common/utils';

export const PROVIDED_CONTENT_TYPES_QUERY = 'providedContentTypesQuery';
export type ProvidedContentTypesQuery = typeof PROVIDED_CONTENT_TYPES_QUERY;

export const OrganisationStatus = Enum('ACTIVE', 'INACTIVE');
export type OrganisationStatus = Enum<typeof OrganisationStatus>;

export const ContentType = Enum('isRoutesProvider', 'isStoriesProvider', 'isPlacesProvider', 'isFacilitiesProvider', 'isEventsProvider');
export type ContentType = Enum<typeof ContentType>;

/*
// FIXME: When typescript 4.1 will arrive use this instead:
export type ContentTypesQuery = `${ProvidedContentTypesQuery}.${ContentType}`
export type ContentTypesParams = {
  [Key in `${ProvidedContentTypesQuery}.${ContentType}`]?: boolean;
}
*/
export type ContentTypesQuery =
  | 'providedContentTypesQuery.isRoutesProvider'
  | 'providedContentTypesQuery.isStoriesProvider'
  | 'providedContentTypesQuery.isPlacesProvider'
  | 'providedContentTypesQuery.isFacilitiesProvider'
  | 'providedContentTypesQuery.isEventsProvider';

export type ContentTypesParams = {
  [Key in ContentTypesQuery]?: boolean;
};

export type OrganisationsListParams = ApiPagination<
  {
    name?: string;
    statuses?: string[];
    classifications?: string[];
  } & ContentTypesParams
>;

type OrganisationEntityResponse = {
  description: string;
  id: string;
  logoImagePath: string;
  name: string;
  priority: number;
  website: string;
  mobileDisplayName: string;
  logoImageId: string;
  deactivationEnabled: boolean;
  status: string;
  routeProviderConfig: {
    canPublish: boolean;
    classification: string;
    trustedProvider: boolean;
  };
};

export type OrganisationListResponse = PageableListResponse<OrganisationEntityResponse>;

export interface OrganisationElementVM extends OrganisationEntityResponse {
  trustedStatus: string;
}

export class OrganisationElementVM {
  constructor({ routeProviderConfig, ...props }: OrganisationEntityResponse) {
    Object.assign(this, { ...props, trustedStatus: routeProviderConfig.classification });
  }
}

export class OrganisationListVM extends PageableListVM<OrganisationElementVM, OrganisationListResponse> {}

export interface OrganisationDictionaryElementVM extends SelectOption {}
export class OrganisationDictionaryElementVM {
  constructor({ id, name }: OrganisationEntityResponse) {
    this.label = name;
    this.value = id;
  }
}
export type OrganisationDetailsResponse = OrganisationEntityResponse;

export type UpdateOrganisationDetailsParams = {
  name?: string;
  description?: string;
  logoImageId?: string | number;
  logoImagePath?: string;
  mobileDisplayName?: string;
  priority?: string | number;
  website?: string;
  classification?: string;
};

export type UpdateOrganisationDetailsEntityParams = {
  name?: string;
  description?: string;
  logoImageFile: File[] | CustomFile[];
  mobileDisplayName?: string;
  priority?: string | number;
  website?: string;
  classification?: string;
};

export type AddOrganisationParams = UpdateOrganisationDetailsParams;

export interface OrganisationDetailsVM extends OrganisationEntityResponse {}
export class OrganisationDetailsVM {
  constructor(props: OrganisationEntityResponse) {
    Object.assign(this, props);
  }
}

export interface OrganisationDictionaryVM {
  list: OrganisationDictionaryElementVM[];
}

export class OrganisationDictionaryVM {
  constructor({ content }: OrganisationListResponse) {
    this.list = (content || []).map(element => new OrganisationDictionaryElementVM(element));
  }
}

export type OrganisationChangeImageResponse = {
  accelerometer: null;
  captureTime: null;
  createdAt: string;
  duration: null;
  gyroscope: null;
  id: string;
  likeCounter: number;
  location: null;
  magnetometer: null;
  provider: null;
  providerUrl: null;
  sizeOriginal: number;
  sizeResized: number;
  sourceApp: null;
  type: string;
  urlOriginal: string;
  urlResized: string;
  validationStatus: string;
};

export type AddOrganisationImageResponse = OrganisationChangeImageResponse;

export type CreatedOrganisation = {
  description: string;
  id: string;
  logoImageId: string;
  logoImagePath: string;
  mobileDisplayName: string;
  name: string;
  priority: number;
  website: string;
};

export interface OrganisationAutocompleteElementVM extends AsyncSelectOption {}

export type OrganisationAutocompleteListResponse = PageableListResponse<OrganisationEntityResponse>;
export class OrganisationAutocompleteElementVM {
  constructor({ id, name }: Pick<OrganisationEntityResponse, 'id' | 'name'>) {
    this.label = name || '';
    this.value = id || '';
  }
}

export interface OrganisationAutocompleteListVM {
  list: OrganisationAutocompleteElementVM[];
}

export class OrganisationAutocompleteListVM {
  constructor(data: OrganisationAutocompleteListResponse) {
    this.list = (data.content || []).map(element => new OrganisationAutocompleteElementVM(element));
  }
}

export type OrganisationStatusResponse = {
  id: string;
  displayName: string;
  translationId: string;
};

export type OrganisationStatusListResponse = { entries: OrganisationStatusResponse[] };

export interface OrganisationStatusElementVM extends SelectOption {}

export class OrganisationStatusElementVM {
  constructor({ displayName, id, translationId }: OrganisationStatusResponse) {
    Object.assign(this, {
      translationId,
      label: displayName,
      value: id,
    });
  }
}

export interface OrganisationStatusDictionaryVM {
  list: OrganisationStatusElementVM[];
}

export class OrganisationStatusDictionaryVM {
  constructor(list: OrganisationStatusListResponse) {
    this.list = (list.entries || []).map(element => new OrganisationStatusElementVM(element));
  }
}
