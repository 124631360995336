import React, { type ReactNode, useCallback } from 'react';
import { type FieldErrors, type FieldName, type FieldPath, type FieldValues, useController } from 'react-hook-form';
import cn from 'classnames';

import { ErrorMessage as FormErrorMessage, type FieldValuesFromFieldErrors } from '@hookform/error-message';
import { IconButtonLink } from '@/common/components/icon-button';
import { Icon } from '@/common/components/icon';
import { Tooltip } from '@/common/components/tooltip';
import { useStyles } from '@/styles/hooks';
import { ErrorMessage, type ErrorMessageProps } from '../error-message';
import { Label } from '../label';
import { type FieldProps } from './model';
import { fieldStyles } from './styles';

export const Field = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>({
  name,
  label,
  labelIcon,
  hint,
  children,
  preventLabelNativeEvents,
  errorComponent: ErrorComponent,
  tooltipMessage,
  filePreviewLink,
  isFilePreviewDisabled,
  className,
  errorClassName,
  link,
  isFocusOnLabelEnabled = true,
  hideErrorMessage,
  ...rest
}: FieldProps<TFieldValues, TName>) => {
  const controller = useController({ name, ...rest });
  const {
    fieldState: { invalid, error },
    formState: { errors },
  } = controller;

  const internalErrorClassName = cn(['error-message', errorClassName]);

  const ErrorMessageComponent = useCallback<(props: ErrorMessageProps) => ReactNode>(
    props =>
      ErrorComponent ? <ErrorComponent {...props} className={internalErrorClassName} /> : <ErrorMessage className={internalErrorClassName} {...props} />,
    [ErrorComponent, internalErrorClassName]
  );

  const { styles } = useStyles(fieldStyles);

  return (
    <div css={styles} className={className}>
      <Label isFocusOnLabelEnabled={isFocusOnLabelEnabled}>
        <>
          {label !== undefined && (
            <div className='label'>
              {label}
              {labelIcon}
              {filePreviewLink && (
                <IconButtonLink className='link-button' iconName='mdi-eye' theme='primary' rel='noreferrer' link={filePreviewLink} external />
              )}
              {isFilePreviewDisabled && <Icon name='mdi-eye' className='label-icon label-icon--disabled' />}
              {tooltipMessage && (
                <Tooltip message={tooltipMessage} placement='right-end'>
                  <Icon name='mdi-information' className='label-icon' />
                </Tooltip>
              )}
              {link && <IconButtonLink className='link-button' iconName='mdi-link' theme='primary' link={link} external />}
            </div>
          )}
          {children({ controller })}
        </>
      </Label>
      {!hideErrorMessage &&
        (error && invalid ? (
          <FormErrorMessage
            errors={errors}
            name={name as unknown as FieldName<FieldValuesFromFieldErrors<FieldErrors<TFieldValues>>>}
            render={ErrorMessageComponent}
          />
        ) : (
          <div className='error-message-placeholder'></div>
        ))}
    </div>
  );
};
